<template>
  <div class="iframe-section">
    <iframe
      src="https://player.vimeo.com/video/1017554102?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      class="iframe-content"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'IframeSection',
};
</script>

<style scoped>
.iframe-section,
.iframe-content {
  width: 1180px;
  height: 666px;
}
</style>
